<template>
  <section class="user-contacts" @click="checkEmailsAndCopy">
    <h3 class="user-contacts__title">
      Контакты <EditButton v-if="contacts.edit" :href="contacts.edit" />
    </h3>

    <p v-if="showDialogsNotice" class="user-contacts__notice">
      <a :href="`/conversations/${userLogin}`">Свяжитесь со специалистом</a>
      через диалог на Хабр Карьере — тогда вероятность, что он ответит на ваше
      сообщение, увеличится в два раза.
    </p>

    <ul v-if="contacts.items.length" class="user-contacts__list">
      <UserContactsItem
        v-for="(contact, idx) in contacts.items"
        @click.native="sendAnalytycs($event, contact)"
        :contact="contact"
        :key="contact.kind + contact.value.title + idx"
      />
    </ul>

    <template v-else-if="contacts.placeholderItems.length">
      <BasicText
        v-for="placeholder in contacts.placeholderItems"
        :key="placeholder.value"
        size="small"
        color="muted"
      >
        {{ placeholder.value }}
      </BasicText>
    </template>
  </section>
</template>

<script>
import { notify } from 'src/notify.js';

import EditButton from 'src/components/buttons/EditButton/EditButton.vue';
import BasicText from 'src/components/text/BasicText/BasicText.vue';
import UserContactsItem from 'src/components/users/UserContactsItem/UserContactsItem.vue';

export default {
  name: 'UserContacts',
  components: { EditButton, UserContactsItem, BasicText },
  props: {
    contacts: {
      type: Object,
      required: true,
      validator(value) {
        return Array.isArray(value.items);
      },
    },
    visitor: {
      type: Object,
      default: null,
    },
    userLogin: {
      type: String,
      required: true,
    },
    curatorLogin: {
      type: String,
    },
  },
  methods: {
    async checkEmailsAndCopy(event) {
      const href = `${event.target?.getAttribute('href')}`;

      if (!href.startsWith('mailto:')) return;
      const email = href.replace('mailto:', '');
      event.preventDefault();

      try {
        await navigator.clipboard.writeText(email);
        notify({ message: 'Адрес скопирован' });
      } catch (err) {
        notify({
          message: 'Не удалось скопировать email-адрес',
          type: 'error',
        });
      }
    },

    getAnalytycsEventPostfix(contact) {
      const { type, kind } = contact;
      const kindsMap = {
        vkontakte: 'social_vk',
        facebook: 'social_fb',
        linkedin: 'social_ld',
        telegram: 'tlg_tlg',
      };
      const typesMap = {
        phone: 'tel_tel',
        email: 'email_email',
        site: 'link',
      };
      const byKind = kindsMap[kind];
      const byType = typesMap[type];

      return byKind ?? byType;
    },

    sendAnalytycs(event, contact) {
      const href = event.target?.getAttribute('href');

      if (!href) return;

      const eventPostfix = this.getAnalytycsEventPostfix(contact);

      if (!eventPostfix) return;

      const eventName = `contacts_${eventPostfix}`;

      window.helpers.trackEventByName(eventName);
    },
  },
  computed: {
    showDialogsNotice() {
      if (this.visitor?.company) return true;

      return this.contacts.items.length === 0;
    },
  },
};
</script>
