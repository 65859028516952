<template>
  <UserPageTemplate :user="user">
    <UserResume
      v-if="resume"
      :resume="resume"
      :user="user"
      :has-active-connection="hasActiveConnection"
      :show-expert-score-form="visibleConnectionFeedback"
      :skills-banner="skillsBanner"
    />
    <SectionBox v-else>
      <BasicSection>
        <UserPagePlaceholder
          :user="user"
          :placeholder="placeholder"
        />
      </BasicSection>
    </SectionBox>
  </UserPageTemplate>
</template>

<script>
import SectionBox from 'src/components/SectionBox/SectionBox.vue';
import BasicSection from 'src/components/sections/BasicSection/BasicSection.vue';
import UserPagePlaceholder from 'src/components/users/UserPagePlaceholder/UserPagePlaceholder.vue';
import UserPageTemplate from 'src/components/users/UserPageTemplate/UserPageTemplate.vue';
import UserResume from 'src/components/users/UserResume/UserResume.vue';

export default {
  name: 'UserPage',
  components: {
    UserPageTemplate,
    BasicSection,
    SectionBox,
    UserPagePlaceholder,
    UserResume,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    resume: Object,
    placeholder: Object,
    hasActiveConnection: Boolean,
    visibleConnectionFeedback: Boolean,
    skillsBanner: Object,
  },
};
</script>
