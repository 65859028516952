<template>
  <div class="expert-profile">
    <ExpertStats :score="expert.score" />
    <ExpertLinksSection
      title="Работаю с квалификациями"
      :list="expert.qualifications"
    />
    <ExpertLinksSection
      title="Специализация"
      :list="expert.specializations"
    />
    <ExpertLinksSection
      v-if="expert.skills && expert.skills.length"
      title="Помогаю развивать навыки"
      :list="expert.skills"
    />
    <ContentSection
      title="Работаю с запросами"
      appearance="expert-profile"
    >
      <ExpertRequestsList :requests="expert.requests" />
    </ContentSection>
    <ContentSection
      v-if="expert.description"
      title="Экспертность"
      appearance="expert-profile"
    >
      <FadedContent
        :show="false"
        expand-title="Развернуть"
        collapse-title="Свернуть"
        button-appearance="pseudo-link-bold"
        appearance="inline"
        minimal
      >
        <StyleUgc :ugc="expert.description" />
      </FadedContent>
    </ContentSection>
    <div class="expert-profile__rate-section">
      <div class="expert-rate">
        <div class="expert-rate__title">Стоимость</div>
        <ExpertCost
          class="expert-rate__cost"
          :rate="expert.rate"
        />
        <BasicButton
          v-if="!user.isOwner && user.conversation"
          appearance="primary"
          class="expert-rate__button"
          size="m"
          :href="connectHref"
          @click="trackConnection"
        >
          {{ connectTitle }}
        </BasicButton>
      </div>
    </div>
  </div>
</template>

<script>

  import { getRussianPluralDeclension } from 'src/utils/translation.js';

  import BasicButton from 'src/components/BasicButton/BasicButton.vue';
  import ExpertCost from 'src/components/experts/ExpertCost/ExpertCost.vue';
  import ExpertLinksSection from 'src/components/experts/ExpertLinksSection/ExpertLinksSection.vue';
  import ExpertRequestsList from 'src/components/experts/ExpertRequestsList/ExpertRequestsList.vue';
  import ExpertStats from 'src/components/experts/ExpertStats/ExpertStats.vue';
  import FadedContent from 'src/components/FadedContent/FadedContent.vue';
  import ContentSection from 'src/components/sections/ContentSection/ContentSection.vue';
  import StyleUgc from 'src/components/StyleUgc/StyleUgc.vue';

  export default {
    name: 'ExpertProfile',
    components: { ExpertCost, ExpertStats, ExpertLinksSection, ExpertRequestsList, FadedContent, BasicButton, StyleUgc, ContentSection },
    props: {
      expert: {
        type: Object,
        required: true,
      },
      user: {
        type: Object,
        required: true,
      },
      hasActiveConnection: Boolean,
    },
    methods: {
      trackConnection() {
        if (!this.hasActiveConnection) {
          window.helpers.trackEvent('experts', 'contact', 'ex_profile');
        }
      },
    },
    computed: {
      consultsText() {
        return getRussianPluralDeclension(
          'консультация',
          'консультации',
          'консультаций',
        )(this.expert.total_connections)
      },
      scoresText() {
        return getRussianPluralDeclension(
          'оценка',
          'оценки',
          'оценок',
        )(this.expert.scores_count)
      },
      connectTitle() {
        if (!this.hasActiveConnection) {
          return 'Связаться';
        }

        return this.user.conversation && this.user.conversation.title;
      },
      connectHref() {
        return this.hasActiveConnection
          ? `/conversations/${this.user.id}`
          : this.expert.connectHref;
      },
    }
  };
</script>
