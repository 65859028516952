<template>
  <div class="resume-rating-item">
    <WithAvatarAside>
      <template #avatar>
        <SocialRatingIcon
          :href="rating.href"
          :icon="rating.icon"
        />
      </template>
      <template #default>
        <BasicText size="large" weight="bold">
          <BasicLink
            :href="rating.href"
            :title="rating.title"
            appearance="dark"
          />
        </BasicText>
        <div class="resume-rating-item__meta">
          <BasicLink
            :title="rating.user.title"
            :href="rating.user.href"
          />,
          <span v-text="rating.duration" />
        </div>
        <div class="resume-rating-item__contribution" v-text="rating.contribution" />
        <div class="resume-rating-item__topics">
          <InlineList :items="rating.topics" v-slot="{ item }">
            <RankedItem :item="item" />
          </InlineList>
        </div>
      </template>
    </WithAvatarAside>
  </div>
</template>

<script>
  import BasicLink from "src/components/BasicLink/BasicLink.vue";
  import InlineList from "src/components/InlineList/InlineList.vue";
  import RankedItem from 'src/components/RankedItem/RankedItem.vue';
  import SocialRatingIcon from "src/components/SocialRatingIcon/SocialRatingIcon.vue";
  import BasicText from "src/components/text/BasicText/BasicText.vue";
  import WithAvatarAside from "src/components/WithAvatarAside/WithAvatarAside.vue";

  export default {
    name: 'ResumeRatingItem',
    components: { RankedItem, BasicText, InlineList, BasicLink, SocialRatingIcon, WithAvatarAside },
    props: {
      rating: {
        type: Object,
        required: true,
      }
    },
  }
</script>