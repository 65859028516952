<template>
  <div
    class="basic-textarea"
    :class="bem('basic-textarea', { appearance, invalid })"
  >
    <textarea
      ref="textarea"
      class="basic-textarea__textarea"
      :rows="rows"
      :name="name"
      :id="id"
      :placeholder="placeholder"
      :maxlength="maxLength"
      v-model="model"
      v-on="extraListeners"
      v-autosize
      :style="{ '--textarea--maxRows': maxRows }"
    />
    <TextLength v-if="maxLength" :max-length="maxLength" :value="model" />
  </div>
</template>

<script>
import { autosizeDirective } from 'src/utils/autosize.js';
import { bem } from 'src/utils/bem.js';
import { createPropModel } from 'src/utils/vue.js';

import TextLength from 'src/components/TextLength/TextLength.vue';

export default {
  name: 'BasicTextarea',
  components: { TextLength },
  directives: {
    autosize: autosizeDirective,
  },
  props: {
    value: String,
    name: String,
    id: String,
    placeholder: String,
    rows: {
      type: [String, Number],
      default: 1,
    },
    maxRows: {
      type: [String, Number],
      default: 5,
    },
    appearance: String,
    invalid: Boolean,
    maxLength: Number,
  },
  methods: {
    bem,
    focus() {
      this.$refs.textarea.focus();
    },
  },
  computed: {
    model: createPropModel(),
    extraListeners() {
      const { input, ...rest } = this.$listeners;
      return rest;
    },
    exceeded() {
      return this.maxLength && this.model.length >= this.maxLength;
    },
  },
};
</script>
