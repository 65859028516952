import { apiUrl, apiUrlV1, cancellableRequest } from 'src/services/api.js';

export const PersonalResource = {
  acceptFriendRequest({ userId }) {
    return cancellableRequest({
      url: `${apiUrl}/users/${userId}/friendship_requests/approve`,
      method: 'patch'
    });
  },
  rejectFriendRequest({ userId }) {
    return cancellableRequest({
      url: `${apiUrl}/users/${userId}/friendship_requests/reject`,
      method: 'patch'
    });
  },
  getFriends({ userId, page }) {
    return cancellableRequest({
      url: `${apiUrl}/users/${userId}/friendships`,
      method: 'get',
      params: {
        page
      }
    });
  },
  getFriendRequests({ userId, page }) {
    return cancellableRequest({
      url: `${apiUrl}/users/${userId}/friendship_requests`,
      method: 'get',
      params: {
        page
      }
    });
  },
  toggleFriendship({ userId, status, captcha }) {
    return cancellableRequest({
      url: `${apiUrl}/users/${userId}/friendships`,
      method: 'post',
      params: {
        status,
        'g-recaptcha-response': captcha
      }
    });
  },
  addRecommendationLetter({ data, userId }) {
    return cancellableRequest({
      url: `${apiUrl}/users/${userId}/opinions`,
      method: 'post',
      data
    });
  },
  deleteRecommendationLetter({ letterId }) {
    return cancellableRequest({
      url: `${apiUrl}/opinions/${letterId}`,
      method: 'delete'
    });
  },
  toggleRecommendationLetterApprove({ letterId, userId }) {
    return cancellableRequest({
      url: `${apiUrl}/users/${userId}/opinions/${letterId}/toggle_endorse`,
      method: 'patch'
    });
  },
  getRecommendationLetterVoters({ letterId }) {
    return cancellableRequest({
      url: `${apiUrl}/opinions/${letterId}/endorsers`,
      method: 'get'
    });
  },
  reportUser({ userId, data }) {
    return cancellableRequest({
      url: `${apiUrl}/users/${userId}/complaints`,
      method: 'post',
      data
    });
  },
  // TODO: remove
  removeFriend({ id }) {
    return cancellableRequest({
      url: `${apiUrl}/personal/friends/${id}`,
      method: 'delete'
    });
  },
  getCompaniesLimits() {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrlV1}/users/user_companies_with_limits`,
    });
  },
};
