<template>
  <li class="user-contacts-item">
    <SvgIcon
      class="user-contacts-item__icon"
      source="/images/contacts-v2.svg"
      :icon="icon"
      size="28"
    />
    <LinkOrText
      target="_blank"
      appearance="blue"
      rel="noopener noreferer"
      :item="contactValue"
    />
  </li>
</template>

<script>
import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';
import LinkOrText from 'src/components/LinkOrText/LinkOrText.vue';

export default {
  name: 'UserContactsItem',
  components: {
    SvgIcon,
    LinkOrText,
  },
  inject: ['mediaQueries'],
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
  computed: {
    contactValue() {
      if (this.contact.type === 'phone' && this.mediaQueries.mobile) {
        return {
          ...this.contact.value,
          href: `tel:${this.contact.value.title}`,
        };
      }

      return this.contact.value;
    },
    icon() {
      const customIcons = [
        'vkontakte',
        'telegram',
        'email',
        'facebook',
        'linkedin',
        'skype',
        'behance',
        'dribbble',
        'gitlab',
        'stackoverflow',
        'github',
      ];

      if (customIcons.includes(this.contact.kind)) return this.contact.kind;

      if (this.contact.type === 'phone') return 'phone';

      return 'link';
    },
  },
};
</script>
